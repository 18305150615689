import { Control } from 'react-hook-form';

export enum RadioButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

export enum RadioButtonLabelStyle {
  Body = 'body',
  Title = 'title',
}

export interface RadioOption {
  value: string;
  label: string;
  disabled?: boolean;
}

type AccessibleLabelProps =
  | {
      label?: never;
      labelStyle?: never;
      labelledBy: string;
    }
  | {
      label: string;
      labelStyle?: RadioButtonLabelStyle;
      labelledBy?: never;
    };

export type RadioButtonsProps = {
  id: string;
  options: RadioOption[];
  className?: string;
  disabled?: boolean;
  margin?: string;
  initialValue?: string;
  control?: Control;
  size?: RadioButtonSize;
  optionMargin?: string;
  onChange?: (option: RadioOption) => void;
  required?: boolean;
  fullWidth?: boolean;
} & AccessibleLabelProps;
