import _ from 'lodash';

import { CondensedVisitType, VisitType } from 'ev-types';

import { sanitizeId } from 'ev-api/utils';

import {
  IncludedIntakeForm,
  IncludedIntakeFormQuestion,
  VisitTypeResponse,
  VisitTypeResponseForGetVisits,
  VisitTypesResponse,
} from './responses';

export const visitTypeTransformer = (
  response: VisitTypeResponse,
): VisitType => {
  const { data } = response;
  const { attributes } = data;

  const intakeFormId = data.relationships.active_intake_form.data.id;

  const intakeForm = _.chain(response.included)
    .filter(isIntakeForm)
    .find({
      id: intakeFormId,
    })
    .value();

  const reasonQuestionIds = _.map(
    intakeForm.relationships.reasons.data,
    reason => reason.id,
  );

  const descriptionQuestionIds = _.map(
    intakeForm.relationships.descriptions.data,
    reason => reason.id,
  );

  const questionIds = reasonQuestionIds.concat(descriptionQuestionIds);

  return {
    ...data,
    id: sanitizeId(data.id),
    attributes: {
      ...attributes,
      id: sanitizeId(attributes.id),
      practice_id: sanitizeId(attributes.practice_id),
      first_available_provider: attributes.first_available_provider
        ? sanitizeId(attributes.first_available_provider)
        : null,
      auto_assigned_provider_id: attributes.auto_assigned_provider_id
        ? sanitizeId(attributes.auto_assigned_provider_id)
        : null,
      billing_default_price_cents: _.isString(
        attributes.billing_default_price_cents,
      )
        ? parseFloat(attributes.billing_default_price_cents)
        : attributes.billing_default_price_cents,
      insurance_manual_price_cents: _.isString(
        attributes.insurance_manual_price_cents,
      )
        ? parseFloat(attributes.insurance_manual_price_cents)
        : attributes.insurance_manual_price_cents,
    },
    intakeForm: _.chain(response.included)
      .filter(isFormQuestion)
      .filter(question => _.includes(questionIds, question.id))
      .sortBy('id')
      .map(field => ({
        ...field.attributes,
        id: sanitizeId(field.id),
      }))
      .value(),
  };
};

export const visitTypesTransformer = (
  response: VisitTypesResponse,
): VisitType[] => {
  return _.map(response.data, responseData =>
    visitTypeTransformer({
      data: responseData,
      included: response.included,
    }),
  );
};

export const visitTypeForGetVisitsTransformer = (
  response: VisitTypeResponseForGetVisits,
): CondensedVisitType => {
  const { attributes } = response;

  return {
    ...response,
    id: sanitizeId(response.id),
    attributes: {
      ...attributes,
      id: sanitizeId(response.id),
    },
  };
};

const isFormQuestion = (
  value: IncludedIntakeForm | IncludedIntakeFormQuestion,
): value is IncludedIntakeFormQuestion => {
  return value.type === 'dynamic_form_field';
};

const isIntakeForm = (
  value: IncludedIntakeForm | IncludedIntakeFormQuestion,
): value is IncludedIntakeForm => {
  return value.type === 'intake_form';
};
