import {
  CustomAction,
  CustomerPracticeDevice,
  CustomVisitStatus,
  Segment,
} from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { sanitizeAndTransformResponse } from 'ev-api/common/transformers';

import {
  CreateCustomColumnParams,
  CreateCustomStatusParams,
  CustomerIdParam,
  DeleteCustomColumnParams,
  DeleteCustomStatusParams,
  GetColumnsParams,
  GetCustomActionsParams,
  GetCustomDataKeyMapParams,
  GetCustomStatusesParams,
  UpdateCustomActionParams,
  UpdateCustomColumnParams,
  UpdateCustomerParams,
  UpdateCustomStatusOrderParams,
  UpdateCustomStatusParams,
  UpdateSegmentParams,
} from './params';
import { ColumnData, CustomDataKeyMap, Customer } from './types';

export const adminApi = api.injectEndpoints({
  endpoints: builder => ({
    getColumns: builder.query<ColumnData[], GetColumnsParams>({
      query: ({ customerId, columnSection }) => ({
        url: `${Base.V4}/admin/get_columns`,
        params: {
          customer_id: customerId,
          column_section: columnSection,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.CustomColumns],
    }),
    getCustomDataKeyMap: builder.query<
      CustomDataKeyMap,
      GetCustomDataKeyMapParams
    >({
      query: ({ customerId, columnSection }) => ({
        url: `${Base.V4}/admin/custom_data_key_map`,
        params: {
          customer_id: customerId,
          column_section: columnSection,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.CustomColumns],
    }),
    getSegments: builder.query<Segment[], CustomerIdParam>({
      query: ({ customerId }) => ({
        url: `${Base.V4}/admin/customers/${customerId}/segments`,
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.Segments],
    }),
    getCustomStatuses: builder.query<
      CustomVisitStatus[],
      GetCustomStatusesParams
    >({
      query: ({ segmentId }) => ({
        url: `${Base.V4}/admin/custom_statuses`,
        params: {
          segment_id: segmentId,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.VisitStatuses],
    }),
    updateSegment: builder.mutation<void, UpdateSegmentParams>({
      query: ({ customerId, order, name, segmentId }) => ({
        url: `${Base.V4}/admin/customers/${customerId}/segments/${segmentId}`,
        method: 'PUT',
        body: {
          order,
          name,
        },
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.Segments],
    }),
    getCustomer: builder.query<Customer, CustomerIdParam>({
      query: ({ customerId }) => ({
        url: `${Base.V4}/admin/customers/${customerId}`,
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.Customer],
    }),
    updateCustomer: builder.mutation<Customer, UpdateCustomerParams>({
      query: data => ({
        method: 'PATCH',
        url: `${Base.V4}/admin/customers/${data.customerId}`,
        body: data,
      }),
      transformResponse: sanitizeAndTransformResponse,
      invalidatesTags: [Tags.Customer],
    }),
    getCustomerPracticeDevices: builder.query<
      CustomerPracticeDevice[],
      CustomerIdParam
    >({
      query: ({ customerId }) => ({
        url: `${Base.V4}/admin/customers/${customerId}/practice_devices`,
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.PracticeDevices],
    }),
    createCustomColumn: builder.mutation<void, CreateCustomColumnParams>({
      query: ({
        customerId,
        columnLabelId,
        columnLabel,
        entityType,
        columnSection,
      }) => ({
        url: `${Base.V4}/admin/create_custom_column`,
        method: 'POST',
        body: {
          customer_id: customerId,
          column_label_id: columnLabelId,
          column_label: columnLabel,
          entity_type: entityType,
          column_section: columnSection,
        },
      }),
      invalidatesTags: [Tags.CustomColumns],
    }),
    updateCustomColumn: builder.mutation<void, UpdateCustomColumnParams>({
      query: ({
        customerId,
        customColumnId,
        columnLabelId,
        columnLabel,
        visible,
        available,
        order,
      }) => ({
        url: `${Base.V4}/admin/update_custom_column`,
        method: 'PUT',
        body: {
          customer_id: customerId,
          custom_column_id: customColumnId,
          column_label_id: columnLabelId,
          column_label: columnLabel,
          visible,
          available,
          order,
        },
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.CustomColumns],
    }),
    deleteCustomColumn: builder.mutation<void, DeleteCustomColumnParams>({
      query: ({ customColumnId }) => ({
        url: `${Base.V4}/admin/delete_custom_column`,
        params: { custom_column_id: customColumnId },
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.CustomColumns],
    }),
    createCustomStatus: builder.mutation<void, CreateCustomStatusParams>({
      query: ({ segmentId, order, name, color }) => ({
        url: `${Base.V4}/admin/custom_statuses`,
        method: 'POST',
        body: {
          segment_id: segmentId,
          name,
          order,
          color,
        },
      }),
      invalidatesTags: [Tags.VisitStatuses],
    }),
    updateCustomStatus: builder.mutation<void, UpdateCustomStatusParams>({
      query: ({ segmentId, statusId, order, name, color }) => ({
        url: `${Base.V4}/admin/custom_statuses/${statusId}`,
        method: 'PUT',
        body: {
          segment_id: segmentId,
          name,
          order,
          color,
        },
      }),
      invalidatesTags: [Tags.VisitStatuses],
    }),
    updateCustomStatusOrder: builder.mutation<
      void,
      UpdateCustomStatusOrderParams
    >({
      query: ({ segmentId, order }) => ({
        url: `${Base.V4}/admin/custom_statuses/reorder`,
        method: 'PUT',
        body: {
          segment_id: segmentId,
          order,
        },
      }),
      invalidatesTags: [Tags.VisitStatuses],
    }),
    deleteCustomStatus: builder.mutation<void, DeleteCustomStatusParams>({
      query: ({ segmentId, statusId, newStatusId }) => ({
        url: `${Base.V4}/admin/custom_statuses/${statusId}`,
        params: { segment_id: segmentId, new_status_id: newStatusId },
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.VisitStatuses],
    }),
    getCustomActions: builder.query<CustomAction[], GetCustomActionsParams>({
      query: ({ customerId, section }) => ({
        url: `${Base.V4}/admin/customers/${customerId}/custom_actions`,
        params: {
          section,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
      providesTags: [Tags.CustomActions],
    }),
    updateCustomAction: builder.mutation<void, UpdateCustomActionParams>({
      query: ({ section, customerId, labelId, label, visible, action }) => ({
        url: `${Base.V4}/admin/customers/${customerId}/custom_actions`,
        method: 'POST',
        body: {
          label,
          visible,
          action,
          section,
          label_id: labelId,
        },
      }),
      invalidatesTags: [Tags.CustomActions],
    }),
  }),
});

export const {
  useGetColumnsQuery,
  useUpdateCustomColumnMutation,
  useUpdateSegmentMutation,
  useCreateCustomColumnMutation,
  useDeleteCustomColumnMutation,
  useCreateCustomStatusMutation,
  useUpdateCustomStatusMutation,
  useUpdateCustomStatusOrderMutation,
  useDeleteCustomStatusMutation,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useGetCustomerPracticeDevicesQuery,
  useGetSegmentsQuery,
  useGetCustomDataKeyMapQuery,
  useGetCustomStatusesQuery,
  useGetCustomActionsQuery,
  useUpdateCustomActionMutation,
} = adminApi;
