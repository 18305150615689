import { useCallback } from 'react';

import { useNullableCurrentPractice } from 'ev-hooks/commonData';

import {
  AvailabilityChangeEventResponse,
  ChatMessageSentEventResponse,
  ChatSessionCreatedEventResponse,
  LiveStatusChangeEventResponse,
  TypingNoteNotificationResponse,
  VisitAttachmentAddedResponse,
  VisitStateChangeEventResponse,
} from './event-types';
import { PusherChannelOptions, usePusherChannelSubscription } from './pusher';

export enum PracticeEvents {
  ProviderAvailabilityChanged = 'providerAvailabilityChanged',
  VisitStateChanged = 'visitStateChanged',
  LiveStatusChanged = 'liveStatusChanged',
  ChatMessageSent = 'visitChatMessageSent',
  ChatTypingNotice = 'client-typingNotice',
  ChatSessionCreated = 'chatSessionCreated',
  VisitAttachmentAdded = 'visitAttachmentAdded',
  VisitAttachmentRemoved = 'visitAttachmentRemoved',
  VisitOverMaxRespondTime = 'visitOverMaxRespondTime',
  VisitNoteUpdate = 'client-visitNoteUpdate',
  VisitNoteUpdateStop = 'client-visitNoteUpdateStop',
}

export const usePracticeNotificationSubscription = <
  Event extends
    | AvailabilityChangeEventResponse
    | VisitStateChangeEventResponse
    | LiveStatusChangeEventResponse
    | ChatMessageSentEventResponse
    | ChatSessionCreatedEventResponse
    | VisitAttachmentAddedResponse
    | TypingNoteNotificationResponse,
>(
  event: PracticeEvents,
  handler: (event: Event) => void,
  options?: PusherChannelOptions,
) => {
  const currentPractice = useNullableCurrentPractice();

  const handleEvent = useCallback(
    (event: unknown) => {
      handler(event as Event);
    },
    [handler],
  );

  return usePusherChannelSubscription(
    `${currentPractice?.attributes.handle}_notification_channel`,
    event,
    handleEvent,
    {
      ...options,
      skipChannelSubscription:
        !currentPractice || options?.skipChannelSubscription,
    },
  );
};
