import { Notifier } from '@airbrake/browser';
import _ from 'lodash';
import React, { createContext, ErrorInfo } from 'react';
import styled from 'styled-components/macro';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { RootState } from 'ev-store';

import Icon from 'ev-components/Icon';
import { AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY } from 'ev-config/config';
import { clearGlobalError } from 'ev-store/actions';
import { connect } from 'ev-store/redux';
import { EVColors } from 'ev-theme/styles';
import Environments from 'ev-types/environments';
import { refresh } from 'ev-utils/browser';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  globalError: string | null;
  clearGlobalError: typeof clearGlobalError;
};

type State = {
  errorMessage: string | null;
};

export const ErrorBoundaryContext = createContext<(error: Error) => void>(
  _.noop,
);

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  airbrake?: Notifier;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      errorMessage: null,
    };

    if (process.env.NODE_ENV !== Environments.Local) {
      this.airbrake = new Notifier({
        projectId: AIRBRAKE_PROJECT_ID,
        projectKey: AIRBRAKE_PROJECT_KEY,
      });
    }
  }

  triggerAirbrakeError = async (error: Error, info?: ErrorInfo) => {
    await this.airbrake?.notify({
      error: error,
      ...(info && {
        params: {
          info,
        },
      }),
    });
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    void this.triggerAirbrakeError(error, info);
  }

  static getDerivedStateFromError(error: Error) {
    return { errorMessage: error.message };
  }

  render() {
    const { children, globalError, clearGlobalError } = this.props;
    const { errorMessage } = this.state;

    const error = globalError || errorMessage;

    const handleClick = () => {
      this.setState({ errorMessage: null });
      if (errorMessage) {
        refresh();
      } else if (globalError) {
        clearGlobalError();
      }
    };

    return (
      <ErrorBoundaryContext.Provider value={this.triggerAirbrakeError}>
        {!errorMessage && children}
        {error && (
          <Banner role="alert">
            <Message>{error}</Message>
            <Button data-testid="dismiss-error" onClick={handleClick}>
              <Icon icon={faTimes} />
            </Button>
          </Banner>
        )}
      </ErrorBoundaryContext.Provider>
    );
  }
}

function select({ globalError }: RootState) {
  return {
    globalError,
  };
}

const mapDispatchToProps = {
  clearGlobalError,
};

export default connect(select, mapDispatchToProps)(ErrorBoundary);

const Banner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  min-height: 64px;
  height: auto;
  background: ${EVColors.maraschino};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  padding: 10px 32px;
`;

const Message = styled.div`
  flex-grow: 1;
  max-width: 100%;
  word-break: break-word;
`;

const Button = styled.button`
  border: none;
  background: none;
  color: white;
  cursor: pointer;
`;
