import styled, { css } from 'styled-components/macro';

// eslint-disable-next-line no-restricted-imports
import Typography, { TypographyProps } from '@mui/material/Typography';

type SimpleVariant = 'title' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type Variant = 'description' | 'footnote' | 'caption' | 'body';

function getVariant(
  variant: Variant,
  bold?: boolean,
  italic?: boolean,
): TypographyProps['variant'] {
  return `${variant}${italic ? 'Italic' : ''}${bold ? 'Bold' : ''}`;
}

function getSimpleVariant(
  variant: SimpleVariant,
  bold?: boolean,
): TypographyProps['variant'] {
  return `${variant}${bold ? 'Bold' : ''}`;
}

type TextProps = {
  variant: Variant;
  bold?: boolean;
  italic?: boolean;
} & Omit<TypographyProps, 'variant'>;
type SimpleTextProps = {
  variant: SimpleVariant;
  bold?: boolean;
} & Omit<TypographyProps, 'variant'>;

const BaseText = ({ variant, bold, italic, children, ...props }: TextProps) => {
  return (
    <Typography variant={getVariant(variant, bold, italic)} {...props}>
      {children}
    </Typography>
  );
};

const SimpleBaseText = ({
  variant,
  bold,
  children,
  ...props
}: SimpleTextProps) => {
  return (
    <Typography variant={getSimpleVariant(variant, bold)} {...props}>
      {children}
    </Typography>
  );
};

export const Text = {
  Headline1: styled(SimpleBaseText).attrs({ variant: 'h1' })``,
  Headline2: styled(SimpleBaseText).attrs({ variant: 'h2' })``,
  Headline3: styled(SimpleBaseText).attrs({ variant: 'h3' })``,
  Headline4: styled(SimpleBaseText).attrs({ variant: 'h4' })``,
  Headline5: styled(SimpleBaseText).attrs({ variant: 'h5' })``,
  Title: styled(SimpleBaseText).attrs({ variant: 'title' })``,
  Body: styled(BaseText).attrs({ variant: 'body' })``,
  Description: styled(BaseText).attrs({ variant: 'description' })``,
  Footnote: styled(BaseText).attrs({ variant: 'footnote' })``,
  Caption: styled(BaseText).attrs({ variant: 'caption' })``,
};

export const Styles = {
  Headline1: css`
    font-size: 28px;
    line-height: 48px;
    display: block;
  `,
  Headline2: css`
    font-size: 26px;
    line-height: 40px;
    display: block;
  `,
  Headline3: css`
    font-size: 24px;
    line-height: 36px;
    display: block;
  `,
  Headline4: css`
    font-size: 22px;
    line-height: 32px;
    display: block;
  `,
  Headline5: css`
    font-size: 20px;
    line-height: 28px;
    display: block;
  `,
  Title: css`
    font-size: 18px;
    line-height: 24px;
    display: block;
  `,
  Body: css`
    font-size: 16px;
    line-height: 20px;
  `,
  Description: css`
    font-size: 14px;
    line-height: 18px;
  `,
  Footnote: css`
    font-size: 12px;
    line-height: 16px;
  `,
  Caption: css`
    font-size: 10px;
    line-height: 16px;
  `,
};

const Regular = new Map();
const SemiBold = new Map();

const basicStyle = css`
  font-family: Inter, sans-serif;
  font-style: normal;
`;

for (const [name, style] of Object.entries(Styles)) {
  Regular.set(
    name,
    css`
      ${basicStyle}
      ${style}
      font-weight: 400;
    `,
  );
  SemiBold.set(
    name,
    css`
      ${basicStyle}
      ${style}
      font-weight: 600;
    `,
  );
}

export const TextStyle = {
  Regular: Object.fromEntries(Regular),
  SemiBold: Object.fromEntries(SemiBold),
};

export default Text;
