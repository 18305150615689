import { useMemo } from 'react';

import { useTranslation } from 'ev-i18n';

import { SortingOptions } from 'ev-api/types';

export enum WRColumns {
  PatientDetails = 'patient-details',
  PatientStatus = 'patient-status',
  ProviderDetails = 'provider-details',
  JoinTime = 'join-time',
  WaitTime = 'wait-time',
  VisitType = 'visit-type',
  ContactInfo = 'contact-info',
  VisitTime = 'visit-time',
  Actions = 'actions',
  Language = 'language',
  PatientLocation = 'patient-location',
  CompletedTime = 'visit_completed',
  CreatedTime = 'visit_created',
  SubmittedTime = 'visit_submitted',
  SignedTime = 'visit_signed',
  AddendumSignedAt = 'visit_addendum_signed',
  OpenText = 'cc_note_1',
  TimeInStatus = 'time_in_status',
  LastChatMessage = 'last_chat_message',
}

export const useWaitingRoomSortingOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: SortingOptions.EarliestToLatest,
        label: t('Earliest to Latest (Default)'),
      },
      {
        value: SortingOptions.LongestWaitTime,
        label: t('Longest Wait Time'),
      },
      {
        value: SortingOptions.NewestStatusChange,
        label: t('Most Recent Status Change'),
      },
      {
        value: SortingOptions.OldestStatusChange,
        label: t('Last Recent Status Change'),
      },
    ],
    [t],
  );
};

// Temporary, will be deleted after the DataGridController refactor
export const useWaitingRoomFields = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      [WRColumns.PatientStatus]: t('Status'),
      [WRColumns.ProviderDetails]: t('Provider Details'),
      [WRColumns.WaitTime]: t('Wait Time'),
      [WRColumns.JoinTime]: t('Join Time'),
      [WRColumns.VisitType]: t('Visit Type'),
      [WRColumns.ContactInfo]: t('Contact Info'),
      [WRColumns.VisitTime]: t('Visit Time'),
      [WRColumns.Language]: t('Language'),
      [WRColumns.PatientLocation]: t('Patient Location'),
    }),
    [t],
  );
};
