import styled from 'styled-components/macro';

import MUIDialog from '@mui/material/Dialog';

import { EVColors } from 'ev-theme/styles';

export type CustomDialogProps = {
  children: React.ReactNode;
  onDismiss: () => void;

  // Will only be false while the dialog is animating out. Allows us to give the
  // user the ability to interact with the app as soon as the dialog is
  // dismissed without waiting for the animation to complete.
  open: boolean;
  overrideMaxWidth?: boolean;
  required?: boolean;
};

const CustomDialog = ({
  children,
  onDismiss,
  open,
  overrideMaxWidth = false,
  required,
}: CustomDialogProps) => {
  const maxWidth = overrideMaxWidth ? false : undefined;
  return (
    <StyledDialog
      maxWidth={maxWidth}
      onClose={required ? undefined : onDismiss}
      open={open}
    >
      {children}
    </StyledDialog>
  );
};

export default CustomDialog;

const WIDTH = 382;

const StyledDialog = styled(MUIDialog)`
  & .MuiDialog-container .MuiPaper-root {
    border-radius: 8px;
    box-shadow: none;
  }
  & .MuiBackdrop-root {
    background-color: ${EVColors.scrim};
    opacity: 1;
    width: ${WIDTH};
    transition: opacity 225ms cubic-bezier(0, 0, 0, 1) 0ms;
  }
`;
