import { ChatSession } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { sanitizeAndTransformResponse } from 'ev-api/common/transformers';

import {
  ChatLastReadAtParams,
  CreateChatMessageParams,
  CreateChatSessionParams,
  GetAvatarParams,
  GetChatSessionParams,
} from './params';
import { GetAvatarResponseData } from './responses';
import { transformChatSession } from './transformers';

const chatSessionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getChatSession: builder.query<ChatSession, GetChatSessionParams>({
      query: ({ id }) => ({
        url: `${Base.V3}/chat_sessions/${id}`,
        method: 'GET',
      }),
      transformResponse: transformChatSession,
    }),
    createChatSession: builder.mutation<ChatSession, CreateChatSessionParams>({
      query: ({ visitId }) => ({
        url: `${Base.V3}/visits/${visitId}/chat_sessions`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Visits],
      transformResponse: transformChatSession,
    }),
    createChatMessage: builder.mutation<void, CreateChatMessageParams>({
      query: ({ sessionId, message }) => ({
        url: `${Base.V3}/chat_sessions/${sessionId}/chat_messages`,
        method: 'POST',
        body: {
          message,
        },
      }),
    }),
    chatLastReadAt: builder.mutation<void, ChatLastReadAtParams>({
      query: ({ sessionId }) => ({
        url: `${Base.V3}/chat_sessions/${sessionId}/chat_last_read_at`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Visits],
    }),
    getAvatars: builder.query<GetAvatarResponseData, GetAvatarParams>({
      query: ({ sessionId }) => ({
        url: `${Base.V4}/chat_sessions/${sessionId}/avatars`,
        method: 'GET',
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
  }),
});

export const {
  useGetChatSessionQuery,
  useCreateChatMessageMutation,
  useCreateChatSessionMutation,
  useChatLastReadAtMutation,
  useGetAvatarsQuery,
} = chatSessionsApi;
