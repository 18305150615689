import { configureStore } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createLogger } from 'redux-logger';

import api from 'ev-api/api';
import { cloudfrontApi } from 'ev-api/cloudfront';
import { errorLogger } from 'ev-api/errors';
import { oldEvaultApi } from 'ev-api/evault';
import { googleMapsApi, googleMapsProxyApi } from 'ev-api/google-maps';
import { stripeApi } from 'ev-api/stripe';
import Environments from 'ev-types/environments';

import slices from './actions';

const reduxLogger = createLogger({
  collapsed: true,
  predicate: (getState, action): boolean => {
    return !_.includes(action.type, 'internal_probeSubscription');
  },
});

const middlewares = [
  errorLogger,
  oldEvaultApi.middleware,
  cloudfrontApi.middleware,
  api.middleware,
  stripeApi.middleware,
  googleMapsApi.middleware,
  googleMapsProxyApi.middleware,
];

if (process.env.NODE_ENV === Environments.Local) {
  middlewares.unshift(reduxLogger);
}

export const store = configureStore({
  reducer: {
    ...slices,
    [oldEvaultApi.reducerPath]: oldEvaultApi.reducer,
    [cloudfrontApi.reducerPath]: cloudfrontApi.reducer,
    [api.reducerPath]: api.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [googleMapsApi.reducerPath]: googleMapsApi.reducer,
    [googleMapsProxyApi.reducerPath]: googleMapsProxyApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck:
        process.env.NODE_ENV === 'test' ? false : { warnAfter: 200 },
      serializableCheck:
        process.env.NODE_ENV === 'test' ? false : { warnAfter: 200 },
    }).concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
