import { detect } from 'detect-browser';

import Environments from 'ev-types/environments';

export const EVAULT_HOST =
  process.env.REACT_APP_EVAULT_HOST || 'https://encryptor.devtest.evisit.com';

export const STRIPE_HOST =
  process.env.REACT_APP_STRIPE_HOST || 'https://api.stripe.com';

export const STRIPE_TEST_API_KEY = process.env.REACT_APP_STRIPE_TEST_API_KEY;
export const STRIPE_LIVE_API_KEY = process.env.REACT_APP_STRIPE_LIVE_API_KEY;

export const AIRBRAKE_PROJECT_ID = parseInt(
  process.env.REACT_APP_AIRBRAKE_PROJECT_ID || '',
);

export const AIRBRAKE_PROJECT_KEY =
  process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || '';
export const URL_PREFIX = '/ui';
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY || '';
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER || 'mt1';
export const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || 'localhost';
export const ENVIRONMENT_NAME = (process.env
  .REACT_APP_INTEGRATIONS_ENVIRONMENT || Environments.Local) as Environments;
export const HTTP_PROTOCOL =
  process.env.NODE_ENV === Environments.Production ? 'https' : 'http';
export const BACKEND_PORT =
  process.env.NODE_ENV === Environments.Production ? 443 : 3000;
export const DEFAULT_SUPPORT_NUMBER = '9282972294';
export const CLOUDFRONT_ALERTS_URL =
  process.env.REACT_APP_ALERTS_CLOUDFRONT_URL ||
  'https://d3fh0sm57ewv7l.cloudfront.net/';
export const MUI_KEY = process.env.REACT_APP_MUI_KEY || '';
export const isReactNative = () => !!window.ReactNativeWebView;
export const FULL_CALENDAR_LICENSE_KEY =
  process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY || '';
export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const GOOGLE_MAPS_API_URL =
  process.env.REACT_APP_GOOGLE_MAPS_URL ||
  'https://maps.googleapis.com/maps/api';
export const NODE_ENV = process.env.NODE_ENV;
export const IS_JEST = typeof jest !== 'undefined';

const MimicPrefixes = {
  [Environments.Local]: 'api-local',
  [Environments.Test]: 'api-local',
  [Environments.Poc]: 'api.poc',
  [Environments.DevTest]: 'api.devtest',
  [Environments.Acceptance]: 'api.acceptance',
  [Environments.Beta]: 'api.beta',
  [Environments.Production]: 'api',
};

export const MIMIC_SIGN_IN_URL = `https://${MimicPrefixes[ENVIRONMENT_NAME]}.evisit.com/api/v3/users/sign_in`;

export const BACKEND_PROXY =
  ENVIRONMENT_NAME === Environments.Local
    ? process.env.REACT_APP_BACKEND_PROXY
    : undefined;

const browserInfo = detect();
export const isAndroid = browserInfo?.os === 'Android OS';
export const isIOSChrome = browserInfo?.name === 'crios';
