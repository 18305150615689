import _ from 'lodash';
import { LiteralUnion } from 'type-fest';

import { Id } from 'ev-types';

import { InsuranceValidationErrorResponse } from 'ev-api/core';
import { isArray, isObject } from 'ev-utils/types';

export const buildQueryParams = (
  values: Array<{
    key: string;
    value: string | string[] | number | boolean | undefined;
    format?: (value: string) => string;
  }>,
) => {
  const params = new URLSearchParams();
  values.forEach(({ key, value, format }) => {
    const addKeyPair = (key: string, value: string | number | boolean) =>
      params.append(key, format ? format(value.toString()) : String(value));
    if (isArray(value)) {
      _.map(value, item => addKeyPair(`${key}[]`, item.toString()));
    } else if (!_.isNil(value)) {
      addKeyPair(key, value);
    }
  });
  return params.toString();
};

export type Sanitized<ObjectType> = {
  [Key in keyof ObjectType]: Key extends 'id'
    ? string
    : Key extends 'updated_by'
      ? string
      : Key extends `${string}_id`
        ? string
        : Key extends `${string}_ids`
          ? string[]
          : ObjectType[Key] extends object
            ? Sanitized<ObjectType[Key]>
            : ObjectType[Key];
};

type SanitizableKeys = LiteralUnion<
  'id' | `${string}_id` | `${string}_ids` | 'updated_by' | string,
  PropertyKey
>;

export type SanitizeRecordType = Record<SanitizableKeys, unknown>;
type SanitizeArrayType = Array<[SanitizableKeys, unknown]>;

export const sanitizeIds = <T extends SanitizeRecordType | undefined>(
  responseObject: T,
): Sanitized<T> => {
  if (_.isEmpty(responseObject)) {
    return responseObject as Sanitized<T>;
  }
  const sanitized = {} as Sanitized<T>;
  for (const [key, value] of _.entries(responseObject) as SanitizeArrayType) {
    const isEmptyObject = _.isEmpty(value);
    if (isId(key) && _.isNumber(value)) {
      _.assign(sanitized, { [key]: value.toString() });
    } else if (
      isIds(key) &&
      _.isArray(value) &&
      !_.isEmpty(value) &&
      _.isNumber(value[0])
    ) {
      _.assign(sanitized, { [key]: value.map(el => String(el)) });
    } else if (typeof value === 'object' && !isEmptyObject) {
      if (!_.isArray(value)) {
        _.assign(sanitized, {
          [key]: sanitizeIds(value as SanitizeRecordType),
        });
      } else if (typeof value[0] === 'object') {
        _.assign(sanitized, { [key]: value.map(el => sanitizeIds(el)) });
      } else {
        _.assign(sanitized, { [key]: value });
      }
    } else {
      _.assign(sanitized, { [key]: value });
    }
  }
  return sanitized;
};

function isId(key: SanitizableKeys): key is 'id' {
  return (
    typeof key === 'string' &&
    (key === 'id' || key === 'updated_by' || key.endsWith('_id'))
  );
}

function isIds(key: SanitizableKeys): key is `${string}_ids` {
  return typeof key === 'string' && key.endsWith('_ids');
}

export const maybeSanitizeId = (id?: string | number): Id | undefined => {
  if (!id) {
    return undefined;
  }

  return sanitizeId(id);
};

export const sanitizeId = (id: string | number): Id => {
  return `${id}`;
};

export const sanitizeNullableId = (id: string | number | null): Id | null => {
  if (id === null) {
    return null;
  }
  return `${id}`;
};

export const sanitizeIdArray = (ids?: Array<string | number>): Id[] => {
  return _.map(ids, sanitizeId);
};

export const convertToInsuranceValidationObjectError = (
  error: unknown,
): InsuranceValidationErrorResponse => {
  if (!isObject(error) || !isObject(error.error)) {
    return {
      status: 401,
      data: {
        validated: false,
        remaining_third_party_requests: 0,
        success: false,
      },
    };
  }
  return error.error as InsuranceValidationErrorResponse;
};
