export const EVColors = {
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  vulcan: 'rgb(17,24,39)',
  asphalt: 'rgb(31,41,55)',
  gunpowder: 'rgb(55,65,81)',
  mako: 'rgb(79,96,119)',
  storm: 'rgb(130,138,156)',
  stone: 'rgb(153,163,180)',
  mercury: 'rgb(182,190,202)',
  aluminum: 'rgb(209,213,219)',
  concrete: 'rgb(243,244,246)',
  alabaster: 'rgb(249,250,251)',
  silverBullet: 'rgb(235,236,240)',
  cobalt: 'rgb(16,62,160)', // Hover Blue
  cerulean: 'rgb(40,95,245)',
  cornflower: 'rgb(147,175,250)', // Focus Blue
  selectedHover: 'rgb(229,236,255)',
  sakura: 'rgb(255,240,245)',
  zircon: 'rgb(238,242,254)', // Background Blue
  sunflower: 'rgb(239,190,18)',
  parakeet: 'rgb(8,196,122)',
  shortcake: 'rgb(255,242,243)',
  bronze: 'rgb(160,102,0)',
  darkBrown: 'rgb(95,69,0)',
  lightYellow: 'rgb(254,205,34)',
  paleYellow: 'rgb(253,242,205)',
  mellowYellow: 'rgb(254,248,236)',
  mint: 'rgb(248,255,254)',
  evisitTeal: 'rgb(41,181,185)',
  periwinkle: 'rgb(234,251,251)',
  pumpkin: 'rgb(146,118,0)',
  sorbet: 'rgb(254,245,222)',
  maraschino: 'rgb(225,0,17)',
  darkRed: 'rgb(204,0,0)',
  hotPink: 'rgb(255,54,121)',
  avatarDarkPurple: 'rgb(75,32,101)',
  avatarDarkPink: 'rgb(88,23,74)',
  avatarDarkBlue: 'rgb(25,51,92)',
  avatarDarkTeal: 'rgb(9,57,71)',
  avatarDarkBrown: 'rgb(64,40,16)',
  avatarDarkGreen: 'rgb(84,82,26)',
  avatarPurple: 'rgb(124,80,152)',
  avatarPink: 'rgb(165,63,136)',
  avatarBlue: 'rgb(65,128,171)',
  avatarTeal: 'rgb(46,107,121)',
  avatarBrown: 'rgb(97,76,58)',
  avatarGreen: 'rgb(113,113,83)',
  lightRed: 'rgb(255,135,135)',
  mediumRed: 'rgba(225, 0, 17, 1)',
  paleRed: 'rgb(255,242,243)',
  shamrock: 'rgb(31,136,0)',
  darkGreen: 'rgb(0,131,89)',
  mediumGreen: 'rgb(6,182,108)',
  lightGreen: 'rgb(118,237,159)',
  paleGreen: 'rgb(238,255,238)',
  mediumOrange: 'rgb(246,118,0)',
  paleOrange: 'rgb(254,245,222)',
  onDemandPurple: 'rgb(131,33,160)',
  appointmentYellow: 'rgb(245,188,40)',
  typography: 'rgb(20,21,24)',
  softBlue: 'rgb(100,139,248)',
  darkSoftBlue: 'rgb(65,108,222)',
  cautionYellow: 'rgb(254,205,34)',
  darkGrayBlue: 'rgb(48,58,64)',
  scrim: 'rgba(0,0,0,0.5)',
  mediumPink: 'rgb(255,54,121)',
  palePink: 'rgb(255,240,245)',
  openBlue: 'rgb(8,40,115)',
  opaqueBlack: 'rgba(0,0,0,0.8)',
  datagridSilver: 'rgba(224,224,224)',
  ptzGray: 'rgba(17, 24, 39, 0.60)',
} as const;

export const PaletteTheme = {
  mode: 'light',
  common: {
    black: EVColors.black,
    white: EVColors.white,
  },
  primary: {
    main: EVColors.cerulean,
    dark: EVColors.cobalt,
    light: EVColors.cornflower,
  },
  error: {
    main: EVColors.maraschino,
    contrastText: EVColors.shortcake,
  },
  warning: {
    main: EVColors.mellowYellow,
    contrastText: EVColors.bronze,
  },
  success: {
    main: EVColors.mint,
    contrastText: EVColors.shamrock,
  },
  info: {
    main: EVColors.concrete,
    contrastText: EVColors.mako,
  },
  text: {
    primary: EVColors.vulcan,
    secondary: EVColors.storm,
    disabled: EVColors.mercury,
  },
  background: {
    default: EVColors.white,
  },
} as const;
