import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_API_URL } from 'ev-config/config';

import { buildQueryParams } from '../utils';
import { transformGeocodeResponse } from './transformers';
import {
  AddressCoordinatesRequestParams,
  AddressCoordinatesResponse,
  GetAddressDetailsParams,
  GetAddressDetailsResponse,
  SearchAddressParams,
  SearchAddressResponse,
} from './types';

const METERS_IN_A_MILE = 1609.344;
const SearchRadius = Math.floor(METERS_IN_A_MILE * 10);

const googleMapsQuery = (baseUrl = GOOGLE_MAPS_API_URL) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      return headers;
    },
  });
};

export const googleMapsApi = createApi({
  reducerPath: 'googleMapsApi',
  baseQuery: googleMapsQuery(),
  endpoints: builder => ({
    getAddressCoordinates: builder.query<
      AddressCoordinatesResponse,
      AddressCoordinatesRequestParams
    >({
      query: ({ address }) => {
        const formattedAddress = [
          address.line1,
          address.line2,
          address.city,
          address.state,
          address.zip,
          address.country,
        ]
          .filter(item => !!item)
          .join(' ');
        const queryParams = buildQueryParams([
          { key: 'address', value: formattedAddress },
          { key: 'key', value: GOOGLE_MAPS_API_KEY },
        ]);
        return {
          url: `/geocode/json?${queryParams}`,
        };
      },
      transformResponse: transformGeocodeResponse,
    }),
  }),
});

const googleMapsProxyQuery = (baseUrl = '/r-static/proxy/maps/api') => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      headers.set('x-target-host', 'https://maps.googleapis.com');
      return headers;
    },
  });
};

export const googleMapsProxyApi = createApi({
  reducerPath: 'googleMapsProxyApi',
  baseQuery: googleMapsProxyQuery(),
  endpoints: builder => ({
    searchAddress: builder.query<SearchAddressResponse, SearchAddressParams>({
      query: ({ input, geolocation }) => {
        // Nebraska fallback
        const lat = geolocation.lat || '40.6375582';
        const lng = geolocation.lng || '-112.3218437';

        return {
          method: 'GET',
          url: '/place/autocomplete/json',
          params: {
            key: GOOGLE_MAPS_API_KEY,
            input,
            radius: SearchRadius,
            types: 'address',
            components: _.map(
              ['us', 'pr', 'vi', 'gu', 'mp'],
              val => `country:${val}`,
            ).join('|'),
            location: `${lat},${lng}`,
          },
        };
      },
    }),
    getAddressDetails: builder.query<
      GetAddressDetailsResponse,
      GetAddressDetailsParams
    >({
      query: ({ placeId }) => ({
        method: 'GET',
        url: '/place/details/json',
        params: {
          key: GOOGLE_MAPS_API_KEY,
          placeid: placeId,
        },
      }),
    }),
  }),
});

export const { useGetAddressCoordinatesQuery } = googleMapsApi;

export const { useLazySearchAddressQuery, useLazyGetAddressDetailsQuery } =
  googleMapsProxyApi;
