import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Id, VisitSegment } from 'ev-types';

type WaitingRoomState = {
  selectedSegment: null | VisitSegment;
  unreadMessages: Record<Id, number>;
};

const initialState: WaitingRoomState = {
  selectedSegment: null,
  unreadMessages: {},
};

export const waitingRoomSlice = createSlice({
  name: 'waitingRoom',
  initialState,
  reducers: {
    selectSegment(state, action: PayloadAction<VisitSegment>) {
      state.selectedSegment = action.payload;
    },
    setUnreadMessages(
      state,
      action: PayloadAction<{ visitId: Id; messageCount: number }>,
    ) {
      const { visitId, messageCount } = action.payload;
      state.unreadMessages[visitId] = messageCount;
    },
  },
});
