import { AnyAction, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { signOutRedirect } from 'ev-hooks/signOut';
import Environments from 'ev-types/environments';
import { isObject, isString } from 'ev-utils/types';

const isARejectedWithValueAction = (anyAction: unknown) =>
  isRejectedWithValue(anyAction);

type Payload = {
  status?: number;
  data: {
    error?: { message: string } | string;
    error_codes?: string[];
    errors?: string[] | Record<string, string>;
    message?: string;
  };
};

type ActionWithPayload = {
  payload: Payload;
};

type ActionWithData = ActionWithPayload & {
  data: {
    handled: boolean;
  };
};

export const isActionPayload = (payload: unknown): payload is Payload =>
  isObject(payload) && isObject(payload.data);

const isActionWithPayload = (action: unknown): action is ActionWithPayload =>
  isObject(action) &&
  isObject(action.payload) &&
  isActionPayload(action.payload);

const isActionWithData = (action: unknown): action is ActionWithData =>
  isObject(action) && isObject(action.data) && isActionWithPayload(action);

const getError = (payload: Payload) => {
  let message = '';

  const { data, status } = payload;

  if (isObject(data.error) && data.error.message) {
    message = data.error.message;
  } else if (isString(data.error)) {
    message = data.error;
  } else if (isString(data.message)) {
    message = data.message;
  } else if (data.error_codes) {
    message = data.error_codes.join(', ');
  } else if (data.errors) {
    if (isObject(data.errors)) {
      message = Object.values(data.errors).flat().join(', ');
    } else {
      message = data.errors.join(', ');
    }
  }

  return { message, status };
};

export const errorLogger: Middleware = () => next => (action: AnyAction) => {
  if (isARejectedWithValueAction(action)) {
    let error;
    if (isActionWithPayload(action) && isActionPayload(action.payload)) {
      error = getError(action.payload);
      if (
        isActionWithData(action) &&
        action.data.handled !== true &&
        action.payload.status === 401
      ) {
        void signOutRedirect(null);
        return;
      }
    }
    if (process.env.NODE_ENV !== Environments.Test) {
      console.error(
        error ? `Error ${error.status}: ${error.message}` : 'Unknown error',
      );
    }
  }
  return next(action);
};
